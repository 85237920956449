import React from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useLabels, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import PropTypes from 'prop-types';

const ClosestDealer = ({
	closestDealer,
	userPostalCode,
	isRenderPortal,
	containerClassNames,
	onClick
}) => {
	const labels = useLabels();

	const { deviceType } = useRequestData();
	const isMobile = deviceType === 'MOBILE';

	if (!isBrowser) {
		return <></>;
	}

	const { name } = closestDealer;

	const containerClassNameArr = ['closest-dealer', ...containerClassNames];

	const btnClassNames = [
		'text-link',
		'text-no-decoration',
		'd-flex',
		'mr-4',
		!isRenderPortal ? 'mb-lg-4' : '',
		isMobile ? 'ddc-font-size-small' : ''
	];

	const labelClassNames = [
		'text-muted',
		'mr-3',
		!isRenderPortal ? 'mb-lg-4' : '',
		isMobile ? 'ddc-font-size-small' : 'ddc-font-size-xsmall'
	];

	return (
		<div className={setClassNames(containerClassNameArr)}>
			<div className={setClassNames(labelClassNames)}>
				{labels.get('ESTIMATE_LOCATION')}
			</div>
			<button
				type="button"
				className={setClassNames(btnClassNames)}
				onClick={onClick}
			>
				<i className="ddc-icon ddc-icon-map-outline2 mr-3" />
				{userPostalCode}
			</button>
			<button
				type="button"
				className={`text-left ${setClassNames(btnClassNames)}`}
				onClick={onClick}
			>
				<i className="ddc-icon ddc-icon-store mr-3" />
				{name}
			</button>
		</div>
	);
};

export default ClosestDealer;

ClosestDealer.propTypes = {
	isRenderPortal: PropTypes.string,
	closestDealer: PropTypes.shape({
		name: PropTypes.string.isRequired
	}),
	userPostalCode: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	containerClassNames: PropTypes.arrayOf(PropTypes.string)
};

ClosestDealer.defaultProps = {
	isRenderPortal: false,
	containerClassNames: []
};
