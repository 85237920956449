import { useState, useEffect, useReducer } from 'react';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { isBrowser } from 'ws-scripts/modules/environment';
import logger from 'ws-scripts/modules/logger';

export const evaluatePath = (path) => {
	return (
		isBrowser &&
		path.split('.').reduce((o, i) => (o[i] ? o[i] : false), window)
	);
};

/**
 * ✧･ﾟ: *✧･ﾟ:* ♥*♡∞:｡.｡ WAIT FOR SOMETHING ｡.｡:∞♡*♥ *:･ﾟ✧*:･ﾟ✧
 * Wait for something to appear in the window object, and then deal with it, whatever it is.
 * If you were hoping for idiomatic react, you've come to the wrong collection of osiris widgets
 * @param {string} where Path within the window object you're expecting and wish to wait for
 * @param {number} delay Interval to check for "where" in ms
 * @param {function} doBefore Function called before waiting
 * @param {function} doAfter Function called after successfully waiting
 * @param {number} giveUpAfter Time in ms to give up waiting
 * @param {function} onGiveUp Function called after giving up
 */
export const waitForSomething = ({
	where,
	delay = 100,
	doBefore = () => {},
	doAfter = () => {},
	giveUpAfter = 20000,
	onGiveUp = () => {}
}) => {
	if (isBrowser) {
		let wait;
		doBefore();
		const timeoutID = setTimeout(() => {
			onGiveUp();
			clearInterval(wait);
		}, giveUpAfter);
		const check = () => {
			const evaluatedPath = evaluatePath(where);
			if (evaluatedPath) {
				clearTimeout(timeoutID);
				clearInterval(wait);
				doAfter();
			}
		};
		wait = setInterval(check, delay);
	}
};

export const getPubsub = () => evaluatePath('DDC.pubsub');

export const usePubsub = () => {
	const [pubsub, setPubsub] = useState(null);
	useEffect(() => {
		(async () => {
			waitForSomething({
				where: 'DDC.pubsub.subscribe',
				doAfter: () => {
					setPubsub(getPubsub());
				},
				giveUpAfter: 5000,
				onGiveUp: () => {
					setNewRelicCustomAttribute(
						'SRP ERROR',
						'DDC.pubsub.subscribe never defined'
					);
				}
			});
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	return pubsub;
};

const initialState = {
	initialized: false,
	isLoading: true,
	isError: false,
	payload: null,
	errorMessage: null
};

const invDataReducer = (state, action) => {
	switch (action.type) {
		case 'INV_DATA_INIT':
			return {
				...state,
				initialized: true,
				isLoading: true,
				isError: false
			};
		case 'INV_DATA_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				payload: action.payload
			};
		case 'INV_DATA_FAILURE':
		default:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: action.payload
			};
	}
};

export const useInvData = ({
	pathToEvaluate,
	pathToReturn = null,
	pubsubTopics = {
		successTopic: null,
		failureTopic: null
	},
	shouldLogErrors = false
}) => {
	const getPathToReturn = () => pathToReturn || pathToEvaluate;
	const { successTopic, failureTopic } = pubsubTopics;
	const [state, dispatch] = useReducer(invDataReducer, initialState);
	const subscribeToTopics = () => {
		const pubsub = getPubsub();
		if (successTopic) {
			pubsub.subscribe(successTopic, () => {
				dispatch({
					type: 'INV_DATA_SUCCESS',
					payload: evaluatePath(getPathToReturn())
				});
			});
		}
		if (failureTopic) {
			pubsub.subscribe(failureTopic, () => {
				dispatch({ type: 'INV_DATA_FAILURE' });
			});
		}
	};

	// wait for what's at 'pathToEvaluate' to be defined
	useEffect(() => {
		(async () => {
			if (!state.initialized) {
				waitForSomething({
					where: pathToEvaluate,
					doBefore: () => {
						dispatch({ type: 'INV_DATA_INIT' });
						const pubsub = getPubsub();
						if (pubsub) {
							subscribeToTopics();
						}
					},
					doAfter: () => {
						if (!getPubsub()) {
							// pubsub doesn't exist for some reason, try waiting for it
							// this may not be necessary, it is probably never the case that
							// invdata is defined before DDC.pubsub.
							waitForSomething({
								where: 'DDC.pubsub.subscribe',
								doAfter: () => {
									subscribeToTopics();
								},
								giveUpAfter: 5000,
								onGiveUp: () => {
									dispatch({ type: 'INV_DATA_FAILURE' });
									setNewRelicCustomAttribute(
										'SRP ERROR',
										'DDC.pubsub.subscribe never defined'
									);
								}
							});
						}
						dispatch({
							type: 'INV_DATA_SUCCESS',
							payload: evaluatePath(getPathToReturn())
						});
					},
					onGiveUp: () => {
						const failedPath = getPathToReturn();
						const errorMsg = `${failedPath} never defined in window object.`;
						if (shouldLogErrors) {
							// we probably don't need this. Errors fetching are captured in ws-inv-data
							setNewRelicCustomAttribute('SRP ERROR', errorMsg);
							logger.error(errorMsg);
						}
						dispatch({
							type: 'INV_DATA_FAILURE',
							errorMessage: errorMsg
						});
					}
				});
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return state;
};

export const useInventory = () =>
	useInvData({
		pathToReturn: 'DDC.InvData.inventory',
		pathToEvaluate: 'DDC.InvData.inventory.inventory',
		pubsubTopics: {
			successTopic: 'ws-inv-data/inventory',
			failureTopic: 'ws-inv-data/inventory/request-failed'
		}
	});

export const useFacets = () =>
	useInvData({
		pathToReturn: 'DDC.InvData.facets',
		pathToEvaluate: 'DDC.InvData.facets.facets',
		pubsubTopics: {
			successTopic: 'ws-inv-data/facets',
			failureTopic: 'ws-inv-data/facets/request-failed'
		}
	});

export const useClosestAccounts = () =>
	useInvData({
		pathToReturn: 'DDC.InvData.closestAccounts',
		pathToEvaluate: 'DDC.InvData.closestAccounts',
		pubsubTopics: {
			successTopic: 'ws-inv-data/closestAccounts',
			failureTopic: 'ws-inv-data/losestAccounts/request-failed'
		}
	});

// export const useAppliedFilters = () =>
// 	useInvData({
// 		pathToReturn: 'DDC.InvData.appliedFilters',
// 		pathToEvaluate:
// 		pubsubTopics: {
// 			successTopic: 'ws-inv-data/sortBy',
// 			failureTopic: 'ws-inv-data/facets/request-failed'
// 		}
// 	});
