import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { validateZip } from '../utilities/validate-zip';

const LocationInput = ({ postalCode, updateLocation }) => {
	const { deviceType } = useRequestData();
	const isDesktop = deviceType === 'DESKTOP';
	const labels = useLabels();

	const [input, setInput] = useState(postalCode);
	const [isValidZip, setValidZip] = useState(validateZip(postalCode));
	const [isAlert, setAlert] = useState(false);
	const inputLabel = labels.get('YOUR_ESTIMATION_ZIP_CODE');

	const handleInput = (e) => {
		const newInput = e.target.value;
		setValidZip(validateZip(newInput));

		if (newInput !== input) {
			setInput(newInput);
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();

		if (e.key === 'Enter' || e.type === 'click') {
			if (isValidZip) {
				updateLocation(input);
				setAlert(false);
			} else {
				setAlert(true);
			}
		}
	};

	const form = (
		<form className="w-100 p-0 mt-4">
			<div className="d-flex w-100 p-0 align-items-stretch form-control-transparent">
				<input
					className={`form-control ${
						isAlert ? 'invalid' : ''
					} mr-3 h-auto`}
					type="search"
					aria-label={labels.get('ENTER_A_NEW_ZIP_CODE')}
					onChange={handleInput}
					onKeyUp={handleFormSubmit}
					placeholder={input}
					value={input}
				/>
				<button
					className={setClassNames([
						'btn',
						'btn-primary',
						'text-nowrap',
						isDesktop ? 'btn-sm' : ''
					])}
					type="submit"
					onClick={handleFormSubmit}
					disabled={!isValidZip}
					title={labels.get('UPDATE_ZIP')}
					aria-label={labels.get('UPDATE_ZIP')}
				>
					{labels.get('UPDATE_ZIP')}
				</button>
			</div>
		</form>
	);
	return (
		<div
			className={setClassNames([isDesktop ? 'ddc-font-size-xsmall' : ''])}
		>
			<h4 className="d-flex align-items-center ddc-font-size-small mt-0 mb-4">
				<i className="ddc-icon ddc-icon-map-outline2 mr-3" />
				<span> {inputLabel}</span>
			</h4>
			{form}
			{isAlert && (
				<span className="d-block mt-1 text-danger">
					{labels.get('AUTOCOMPLETE_HELP_TEXT')}
				</span>
			)}
		</div>
	);
};

LocationInput.propTypes = {
	postalCode: PropTypes.string.isRequired,
	updateLocation: PropTypes.func.isRequired
};

export default LocationInput;
