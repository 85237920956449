import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';
import LocationInput from './LocationInput';
import { trapFocus } from '../utilities/trapFocus';

const LocationModal = ({
	userPostalCode,
	title,
	isOpen,
	onClose,
	isMobile
}) => {
	const [animationName, setAnimationName] = useState(null);
	const labels = useLabels();

	const hideModal = () => {
		// Remove animation className and allow animation transition before removing modal
		setAnimationName(null);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const supportARIA = (modal) => {
		const modalWrap = modal.closest('[aria-hidden]');
		if (modalWrap) {
			modalWrap?.removeAttribute('aria-hidden');
		}
	};

	useEffect(() => {
		if (isOpen) {
			let deActivateFocusTrap = null;

			// Add animation className after modal element is found
			const updateModal = () => {
				const modal = document.querySelector(
					'#location-modal.modal.dock-full'
				);
				if (modal) {
					supportARIA(modal);
					setTimeout(() => setAnimationName('slide'), 50);
					// eslint-disable-next-line no-use-before-define
					clearInterval(intervalModal);
					deActivateFocusTrap = trapFocus(modal);
				}
			};
			const intervalModal = setInterval(updateModal, 50);

			return () => {
				clearInterval();
				if (deActivateFocusTrap) {
					deActivateFocusTrap();
				}
			};
		}

		return () => {};
	}, [isOpen]);

	const updateLocation = (input) => {
		if (input !== userPostalCode) {
			window.DDC.InvData.setUserLocation({
				filterName: 'geoZip',
				filterValues: [input]
			});
			window.localStorage.setItem('userLocation', input);
		}
	};

	return (
		<React.Fragment>
			<Modal
				show={isOpen}
				animation={false}
				onHide={hideModal}
				className={setClassNames([
					isMobile ? 'dock-full' : '',
					animationName
				])}
				aria-modal="true"
				aria-labelledby="location-modal-label"
				id="location-modal"
			>
				<Modal.Body>
					<div className="d-flex position-relative">
						<Modal.Title
							id="location-modal-label"
							className={setClassNames([
								'w-100',
								isMobile ? '' : 'text-center',
								'ddc-font-size-large'
							])}
						>
							{title}
						</Modal.Title>
						<button
							type="button"
							id="hide-location-modal-button"
							className="position-absolute btn-sm h-100 text-link text-decoration-underline text-right"
							onClick={hideModal}
						>
							{labels.get('DONE')}
						</button>
					</div>
					<hr className="modal-divider mb-5" />
					<LocationInput
						postalCode={userPostalCode}
						updateLocation={updateLocation}
					/>
					<hr className="modal-divider my-5" />
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};

LocationModal.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	userPostalCode: PropTypes.string.isRequired
};

export default LocationModal;
